import React from 'react'
import { useParams } from 'react-router-dom'

const Author = () => {
    const { authorName } = useParams();

  return (
    <div>{authorName} - Author Blogs</div>
  )
}

export default Author