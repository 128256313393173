import React from "react";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact/Contact";
import NotFound from "./pages/NotFound/NotFound";
import BlogDetail from "./pages/BlogDetail/BlogDetail";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo/client";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Profile/Login";
import Register from "./pages/Profile/Register";
import PasswordReset from "./pages/Profile/PasswordReset";
import { Auth0Provider } from "@auth0/auth0-react";
import Header from "./components/Header/Header";
import NavBar from "./components/NavBar/NavBar";
import Author from "./pages/Author/Author";

//redux
import { useAppSelector } from "./app/hooks";

//loading toggle
import Loading from "./components/Loading/Loading";
import Modal from "./components/Modal/Modal";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact-us",
    element: <Contact />,
  },
  {
    path: "/blog/:blogId",
    element: <BlogDetail id="" />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profile/login",
    element: <Login />,
  },
  {
    path: "/profile/register",
    element: <Register />,
  },
  {
    path: "/profile/passwordreset",
    element: <PasswordReset />,
  },
  {
    path: "/search",
    element: <Home />,
  },
  {
    path: "/author/:authorName",
    element: <Author />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const domain: string =
  process.env.REACT_APP_AUTH0_DOMAIN !== undefined
    ? process.env.REACT_APP_AUTH0_DOMAIN
    : "";

const clientId: string =
  process.env.REACT_APP_AUTH0_CLIENT_ID !== undefined
    ? process.env.REACT_APP_AUTH0_CLIENT_ID
    : "";

const App = () => {
  const theme = localStorage.getItem("theme");
  if (theme === "light") {
    document.body.removeAttribute("class");
  } else {
    document.body.setAttribute("class", "dark-theme");
  }

  const isLoadingActive = useAppSelector((state) => state.loading.isOpen);
  const isModalActive = useAppSelector((state) => state.modal.isOpen);

  return (
    <>
      <BrowserRouter>
        <HelmetProvider>
          <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
              redirect_uri: `${window.location.origin}/profile`,
              audience: `https://${domain}/api/v2/`,
              scope: "scope: 'openid profile email phone'",
            }}
          >
            <ApolloProvider client={apolloClient}>
              <Header />
              <NavBar />
              <main className="main-wrapper" id="main">
                <Routes>
                  {routes.map((route: any, index: number) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  ))}
                </Routes>
              </main>
              <Footer />
              {/* show loading overlay */}
              {isLoadingActive && <Loading />}

              {/* show modal */}
              {isModalActive && <Modal />}
            </ApolloProvider>
          </Auth0Provider>
        </HelmetProvider>
      </BrowserRouter>
    </>
  );
};

export default App;