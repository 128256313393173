import React, { useEffect } from "react";
import PageHeading from "../../components/PageHeading/PageHeading";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  const formSlug = process.env.REACT_APP_PAPERFORM_SLUG;
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_STORE_NAME} - Contact Us</title>
        <script
          type="text/javascript"
          src="https://paperform.co/__embed.min.js"
          async
        ></script>
      </Helmet>
      <div className="page-wrapper">
        <PageHeading title={"Contact Us"} />
        <div data-spinner="1" data-paperform-id={formSlug} className="contact-us-form" />
      </div>
    </>
  );
};

export default Contact;
