import { createClient } from "contentful";
import { CommentType, UserPostAction } from "../types/BlogType";

const contentful = require("contentful-management");

const contentfulClient: any = () => {
  const space: string =
    process.env.REACT_APP_CONTENTFUL_SPACE_ID !== undefined
      ? process.env.REACT_APP_CONTENTFUL_SPACE_ID
      : "";
  const accessToken: string =
    process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN !== undefined
      ? process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN
      : "";

  const managementAccessToken: string =
    process.env.REACT_APP_CONTENTFUL_MANAGEMENT_TOKEN !== undefined
      ? process.env.REACT_APP_CONTENTFUL_MANAGEMENT_TOKEN
      : "";

  const environment: string =
    process.env.REACT_APP_CONTENTFUL_ENVIROMENT_ID !== undefined
      ? process.env.REACT_APP_CONTENTFUL_ENVIROMENT_ID
      : "";

  const readOnlyClient = createClient({
    space: space,
    accessToken: accessToken,
  });

  const ManagementClientConnect: any = async () => {
    let managementClient = await contentful.createClient({
      accessToken: managementAccessToken,
    });
    let managementSpace = await managementClient.getSpace(space);
    let managementEnv = await managementSpace.getEnvironment(environment);
    return managementEnv;
  };

  const getBlogIds = async () => {
    try {
      const blogIds = await readOnlyClient.getEntries({
        content_type: "itemPost",
        select:
          "sys.id",
        order: "fields.postedTime",
      });
      return blogIds;
    } catch (error) {
      console.log("getBlogIds error", error);
    }
  }

  const getBlogDetail = async (blogId: string) => {
    try {
      const blogDetail = await readOnlyClient.getEntry(blogId);
      return blogDetail;
    } catch (error) {
      console.log("get blog detail error", error);
    }
  };

  const getBlogComments = async (blogId: string) => {
    try {
      const blogComments = await readOnlyClient.getEntries({
        content_type: "comment",
        "fields.blogIdRef.sys.id": blogId,
        order: "sys.createdAt",
      });
      return blogComments;
    } catch (error) {
      console.log("getBlogComments error", error);
    }
  };

  const submitBlogComment = async (newComment: CommentType) => {
    try {
      const payload = {
        fields: {
          content: {
            "en-US": newComment.content,
          },
          userIdRef: {
            "en-US": {
              sys: {
                type: "Link",
                linkType: "Entry",
                id: newComment.userIdRef.id,
              },
            },
          },
          blogIdRef: {
            "en-US": {
              sys: {
                type: "Link",
                linkType: "Entry",
                id: newComment.blogId,
              },
            },
          },
        },
      };

      let env = await ManagementClientConnect();

      let create: any = await env.createEntry("comment", payload);
      let publish: any = await create.publish();
    } catch (error) {
      console.log("submitBlogComment error", error);
    }
  };

  const likeBlog = async (userPostAction: UserPostAction) => {
    try {
      const payload = {
        fields: {
          userEmail: {
            "en-US": userPostAction.userEmail,
          },
          blogId: {
            "en-US": userPostAction.blogId,
          }
        }
      };

      let env = await ManagementClientConnect();

      let create: any = await env.createEntry("liked_blogs", payload);
      let publish: any = await create.publish();
    } catch (error) {
      console.log("likeBlog error", error);
    }
  };

  const unlikeBlog = async (entryId : string) => {
    try 
    {
      //find entryId

      let env = await ManagementClientConnect();
      await env.getEntry(entryId)
      .then((entry : any) => {entry.unpublish(); console.log('unpublished')});

      await env.getEntry(entryId)
      .then((entry : any) => {entry.delete(); console.log('deleted')});
    } 
    catch (error) {
      console.log("unlikeBlog error", error);
    }
  };

  const collectBlog = async (userPostAction: UserPostAction) => {
    try {
      const payload = {
        fields: {
          userEmail: {
            "en-US": userPostAction.userEmail,
          },
          blogId: {
            "en-US": userPostAction.blogId,
          }
        }
      };

      let env = await ManagementClientConnect();

      let create: any = await env.createEntry("blogCollection", payload);
      let publish: any = await create.publish();
    } catch (error) {
      console.log("collectBlog error", error);
    }
  };

  const removeCollectedBlog = async (entryId : string) => {
    try 
    {
      //find entryId
      let env = await ManagementClientConnect();
      await env.getEntry(entryId)
      .then((entry : any) => {entry.unpublish(); console.log('unpublished')});

      await env.getEntry(entryId)
      .then((entry : any) => {entry.delete(); console.log('deleted')});
    } 
    catch (error) {
      console.log("removeCollectedBlog error", error);
    }
  };

  const addBlogToCollection = async (userEmail: string, blogId: string, collectionName: string) => {
    try {
      const payload = {
        fields: {
          userEmail: {
            "en-US": userEmail,
          },
          blogId: {
            "en-US": blogId,
          },
          collectionName: {
            "en-US": collectionName,
          }
        }
      };

      let env = await ManagementClientConnect();

      let create: any = await env.createEntry("blogCollection", payload);
      let publish: any = await create.publish();
    } catch (error) {
      console.log("addBlogToCollection error", error);
    }
  }

  const removeBlogFromCollection = async (entryId : string) => {
    try 
    {
      //find entryId
      let env = await ManagementClientConnect();
      await env.getEntry(entryId)
      .then((entry : any) => {entry.unpublish(); console.log('unpublished')});

      await env.getEntry(entryId)
      .then((entry : any) => {entry.delete(); console.log('deleted')});
    } 
    catch (error) {
      console.log("removeCollectedBlog error", error);
    }
  }
  
  return {
    getBlogDetail,
    getBlogComments,
    getBlogIds,
    submitBlogComment,
    likeBlog,
    unlikeBlog,
    collectBlog,
    removeCollectedBlog,
    addBlogToCollection,
    removeBlogFromCollection
  };
};

export default contentfulClient;
