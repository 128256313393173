import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

const AddBlog = () => {
    const formSlug = process.env.REACT_APP_PAPERFORM_ADD_BLOG_SLUG;
  return (
    <>
    <Helmet>
    <script src="https://paperform.co/__embed.min.js"></script>
    </Helmet>
    <div className="blog-add-wrapper wrapper">
      <div className="blog-add">
        <div data-spinner="1" data-paperform-id={formSlug} />
      </div>
    </div>
    </>
  )
};

export default AddBlog;
