import { gql } from "@apollo/client";
import { apolloClient } from "../apollo/client";
import { getUserCollectedBlogs, getUserLikedBlogs } from "../apollo/queries";
import { UserPostAction } from "../types/BlogType";
import contentfulClient from "../contentful/client";

export const middlewareGetBlogLikeStatus = async (
  userEmail: string,
  blogId: string
) => {
  apolloClient
    .query({
      query: gql`
        ${getUserLikedBlogs(userEmail, blogId)}
      `,
    })
    .then((result) => {
      const likedBlogsCollection: any = result.data.likedBlogsCollection.items;
      if (likedBlogsCollection.length > 0) return true;
      return false;
    });
};

export const toggleLikeBlog = async (
  isLiked: boolean,
  userPostAction: UserPostAction
) => {
  const { likeBlog, unlikeBlog } = contentfulClient();
  const { userEmail, blogId } = userPostAction;

  //unlike blog
  if (isLiked) {
    //find entryId
    await apolloClient
      .query({
        query: gql`
          ${getUserLikedBlogs(userEmail, blogId)}
        `,
      })
      .then((result) => {
        const likedBlogsCollection: any =
          result.data.likedBlogsCollection.items;
        if (likedBlogsCollection.length > 0) {
          const entryId = likedBlogsCollection[0].sys.id;
          unlikeBlog(entryId);
        }
      });
  }
  //like blog
  else {
    await likeBlog(userPostAction);
  }
};

export const toggleCollectedBlog = async (
  isInCollection: boolean,
  userPostAction: UserPostAction
) => {
  const {     collectBlog, removeCollectedBlog } = contentfulClient();
  const { userEmail, blogId, } = userPostAction;
  //unlike blog
  if (isInCollection) {
    //find entryId
    await apolloClient
      .query({
        query: gql`
          ${getUserCollectedBlogs(userEmail, blogId)}
        `,
      })
      .then((result) => {
        const collectedBlogs : any =
          result.data.blogCollectionCollection.items;
        if (collectedBlogs.length > 0) {
          const entryId = collectedBlogs[0].sys.id;
          removeCollectedBlog(entryId);
        }
      });
  }
  //like blog
  else {
    await collectBlog(userPostAction);
  }
};