import { BlogDetailType, CommentType } from "../types/BlogType";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderOptions } from "./ConvertRichTextToHtml";
import { RetrieveBlogImage, RetrieveBlogUser } from "./BlogFieldConvert";

export const blogDetailMappping = (contentfulBlogDetail: any) => {

  const blogDetail: BlogDetailType = {
    itemId: contentfulBlogDetail.itemId,
    contentfulId: contentfulBlogDetail.sys.id,
    title: contentfulBlogDetail.title,
    mediaLinks: contentfulBlogDetail.mediaLinks,
    mediaFilesCollection: contentfulBlogDetail.mediaFilesCollection,
    contentfulMetadata: contentfulBlogDetail.contentfulMetadata,
    description: contentfulBlogDetail.description,
    userProfile: contentfulBlogDetail.userProfile,
    userProfileLink: contentfulBlogDetail.userProfile,
    sourceLink: contentfulBlogDetail.sourceLink,
    enableComment: contentfulBlogDetail.enableComment,
    sys: contentfulBlogDetail.sys
  };
  return blogDetail;
};

export const blogCommentMapping = (contentfulBlogComment: any) => {
  const blogComment: CommentType = {
    id: contentfulBlogComment.sys.id,
    userIdRef: {
      id: contentfulBlogComment.fields.user.sys.id,
      name: contentfulBlogComment.fields.user.fields.name,
      avatar: contentfulBlogComment.fields.user.fields.avatar.fields.file.url,
      profile: "",
      link: ""
    },
    blogId: contentfulBlogComment.fields.blogIdRef.sys.id,
    content: contentfulBlogComment.fields.content,
    createdAt: contentfulBlogComment.sys.createdAt,
  };
  return blogComment;
};
