import React, { useState } from 'react'
import { GiMoon } from "react-icons/gi";

const ThemeSwitcher = () => {

    const iconSize = 24;

    const [isDarkTheme, setTheme] = useState(false);

    const handleThemeSwitch = () => {
        if(isDarkTheme) {
            localStorage.setItem("theme","light");
            document.body.removeAttribute("class");
        }
        else {
            localStorage.setItem("theme","dark");
            document.body.setAttribute("class","dark-theme");
        }
        setTheme(!isDarkTheme); 
    }

  return (
    <div className="outer">
        <button className="theme-switch" onClick={() => handleThemeSwitch()}><span><GiMoon size={iconSize}/></span></button>
    </div>
  )
}

export default ThemeSwitcher