import Card from "../Card/Card";
import { useEffect, useRef, useState } from "react";
import Masonry from "@mui/lab/Masonry";
import { apolloClient } from "../../apollo/client";
import { gql } from "@apollo/client";
import {
  getAllBlogIds,
  getAllBlogIdsBySearchQuery,
  getBlogIdsByTags,
  getAllUserLikedBlogIds
} from "../../apollo/queries";
import { useParams } from "react-router-dom";

//redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { handleSearch } from "../../redux/slices/SearchSlice";

//loading toggle
import { loadingOpen, loadingClose } from "../../redux/slices/LoadingSlice";
import {
  populateContent,
  clearAllFilter,
} from "../../redux/slices/FilterSlice";
import { displayAllBlogs } from "../../redux/slices/BlogSlice";
import { timeout } from "../../utils/Delay";
import Filter from "../Filter/Filter";

const MasonryLayout = ({showFilter, pageType} : {showFilter : boolean, pageType : string}) => {
  const blogList = useAppSelector((state) => state.blog.blogList);

  const searchQuery = useAppSelector((state) => state.search.searchQuery);
  const filterContent = useAppSelector((state) => state.filter.content);
  const displayClearAllButton = useAppSelector(
    (state) => state.filter.displayClearAllButton
  );
  const dispatch = useAppDispatch();

  const getBlogTags = (contentfulBlogIds: any[]) => {
    let tags: any[] = [];
    contentfulBlogIds.map((blogId: any) => {
      if (blogId.contentfulMetadata.tags.length > 0) {
        blogId.contentfulMetadata.tags.map((tag: any) => {
          //if tag does not exist, then add tag
          if (tags.findIndex((element) => element.id === tag.id) === -1)
            tags.push({ id: tag.id, name: tag.name, selected: false });
        });
      }
    });
    dispatch(populateContent(tags));
  };

  const getUserLikedBlogs = async () => {
    dispatch(clearAllFilter());

    apolloClient
      .query({
        query: gql`
          ${getAllUserLikedBlogIds("luan.t3@gmail.com")}
        `,
      })
      .then((result) => {
        const contentfulBlogIds: any[] = result.data.likedBlogsCollection.items;
        let blogItems = contentfulBlogIds.map((item: any) => {
          let contentfulId: string = item.sys.id;
          return { contentfulId: contentfulId };
        });

        dispatch(displayAllBlogs(blogItems));
      });
  }

  const getBlogs = async () => {
    let apolloQuery: string = ``;
    let blogItems: any[] = [];
    dispatch(clearAllFilter());
    if (searchQuery) {
      apolloQuery = getAllBlogIdsBySearchQuery(searchQuery);
    } else {
      apolloQuery = getAllBlogIds();
    }

    apolloClient
      .query({
        query: gql`
          ${apolloQuery}
        `,
      })
      .then((result) => {
        const contentfulBlogIds: any[] = result.data.itemPostCollection.items;
        blogItems = contentfulBlogIds.map((item: any) => {
          let contentfulId: string = item.sys.id;
          // let tags: any[] = item.contentfulMetadata.tags.map((tag: any) => {
          //   return { id: tag.id, name: tag.name };
          // });
          return { contentfulId: contentfulId };
        });
        getBlogTags(contentfulBlogIds);
        dispatch(displayAllBlogs(blogItems));
      });
  };

  const getBLogsWithFilter = async () => {
    const activeFilter: string[] = filterContent.reduce(
      (acc: any, { id, selected }: { id: string; selected: string }) => {
        if (selected) {
          acc.push(id);
        }
        return acc;
      },
      []
    );
    let apolloQuery: string = ``;
    if (activeFilter.length > 0) {
      apolloQuery = getBlogIdsByTags(activeFilter);
      apolloClient
        .query({
          query: gql`
            ${apolloQuery}
          `,
        })
        .then((result) => {
          const contentfulBlogIds: any[] = result.data.itemPostCollection.items;
          let blogItems: any[] = contentfulBlogIds.map((item: any) => {
            let contentfulId: string = item.sys.id;
            return { contentfulId: contentfulId };
          });
          dispatch(displayAllBlogs(blogItems));
        });
    }
  };

  useEffect(() => {
    dispatch(loadingOpen());

    if(pageType === "UserLikedBlogs") {
      getUserLikedBlogs();
    }
    else {
      getBlogs();
    }
    dispatch(loadingClose());
  }, [searchQuery]);

  useEffect(() => {
    dispatch(loadingOpen());
    if (!displayClearAllButton) getBlogs();

    dispatch(loadingClose());
  }, [displayClearAllButton]);

  useEffect(() => {
    const activeContent = filterContent.filter((item : any) => item.selected === true);
    if(activeContent.length > 0) {
      dispatch(loadingOpen());
      timeout(1000).then(() => {
        getBLogsWithFilter();
      })
      .finally(() => {
        dispatch(loadingClose());
      })
    }
  }, [filterContent]);

  return (
    <div className="masonry-main-wrapper">
      {searchQuery && <p className="result-title">Showing result for '{searchQuery}'</p>}
      {/* <RenderFilter /> */}
      <Masonry
        columns={{ xs: 1, sm: 2, md: 3 }}
        spacing={3}
        className="masonry-grid"
      >
        {blogList?.map((blog: any, index: number) => (
          <div className="masonry-item" key={index}>
            <Card blogId={blog.contentfulId} />
          </div>
        ))}
      </Masonry>

      {/* show filter */}
      { (showFilter) && <Filter /> }
    </div>
  );
};

export default MasonryLayout;
