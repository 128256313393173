import { createSlice } from "@reduxjs/toolkit";

interface ModalState {
  isOpen: boolean;
  payload: any;
}

const initialState: ModalState = {
  isOpen: false,
  payload: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    modalOpen: (state,action) => {
      state.isOpen = true;
      state.payload = action.payload;
    },
    modalClose: (state, action) => {
      state.isOpen = false;
      state.payload = null;
    },
    modalChangeContent: (state, action) => {
      state.payload = action.payload;
    }
  },
});

export const { modalOpen, modalClose, modalChangeContent } = modalSlice.actions;

export default modalSlice.reducer;