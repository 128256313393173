import Card from "../Card/Card";
import { useEffect, useRef, useState } from "react";
import Masonry from "@mui/lab/Masonry";
import { apolloClient } from "../../apollo/client";
import { gql } from "@apollo/client";
import {
  getAllBlogIds,
  getAllBlogIdsBySearchQuery,
  getBlogIdsByTags,
  getAllUserLikedBlogIds,
  getBlogsByIds
} from "../../apollo/queries";
import { useParams } from "react-router-dom";

//redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { handleSearch } from "../../redux/slices/SearchSlice";

//loading toggle
import { loadingOpen, loadingClose } from "../../redux/slices/LoadingSlice";
import {
  populateContent,
  clearAllFilter,
} from "../../redux/slices/FilterSlice";
import { displayAllBlogs } from "../../redux/slices/BlogSlice";
import { timeout } from "../../utils/Delay";
import Filter from "../Filter/Filter";

const UserLikedBlogs = ({userEmail} : {userEmail : string}) => {
  // const blogList = useAppSelector((state) => state.blog.blogList);
  const [blogList, setBlogList] = useState<any[]>([]);
  const dispatch = useAppDispatch();

  const getUserLikedBlogs = async (userEmail : string) => {
    const likedBlogIds : string[] = await apolloClient
      .query({
        query: gql`
          ${getAllUserLikedBlogIds(userEmail)}
        `,
      })
      .then((result) => {
        const contentfulBlogIds: any[] = result.data.likedBlogsCollection.items;

        let blogItems = contentfulBlogIds.map((item: any) => {
          return item.blogId
        });
        return blogItems;
      });

    if(likedBlogIds.length > 0) {
      await apolloClient
      .query({
        query: gql`
          ${getBlogsByIds(likedBlogIds)}
        `,
      })
      .then((result) => {
        const contentfulBlogIds: any[] = result.data.itemPostCollection.items;

        let blogItems = contentfulBlogIds.map((item: any) => {
          let contentfulId: string = item.sys.id;
          return { contentfulId: contentfulId };
        });

        setBlogList(blogItems);
      });
    }
  }


  useEffect(() => {
    dispatch(loadingOpen());
      getUserLikedBlogs(userEmail);
    dispatch(loadingClose());
  }, []);


  return (
    <div className="masonry-main-wrapper">
      {/* <RenderFilter /> */}
      <Masonry
        columns={{ xs: 1, sm: 2, md: 3 }}
        spacing={3}
        className="masonry-grid"
      >
        {blogList.map((blog: any, index: number) => (
          <div className="masonry-item" key={index}>
            <Card blogId={blog.contentfulId} />
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export default UserLikedBlogs;
