import React from "react";
import { FaRss, FaTwitter, FaFacebookSquare } from "react-icons/fa";
import NewsletterSubscription from "../NewsletterSubscription/NewsletterSubscription";

const Footer = () => {
  const iconSize = 18;
  return (
    <footer>
    <div className="footer-wrapper">
      {/* <div className="footer-top">
        <div className="overlay">
          <div className="footer-main-content">
            <div className="content-left">
              <h2>Get the latest posts delivered right to your inbox.</h2>
            </div>
            <div className="content-right">
              <NewsletterSubscription />
              <p className="rss-subscription">
                Or subscribe <a href="#">via RSS</a> with Feedly!
              </p>
            </div>
          </div>
          </div>
      </div> */}
      <div className="footer-bottom">
        <div className="footer-main-content">
          <div className="content-left">
            <p>
              © {new Date().getFullYear()} {process.env.REACT_APP_STORE_NAME} · Published with <a href="/">Paul Tran</a>
            </p>
          </div>
          <div className="content-right">
            <ul>
              <li>
                <a href="/">
                  <FaTwitter size={iconSize} />
                </a>
              </li>
              <li>
                <a href="/">
                  <FaFacebookSquare size={iconSize} />
                </a>
              </li>
              <li>
                <a href="/">
                  <FaRss size={iconSize} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div></footer>
  );
};

export default Footer;
