import React, { useState } from "react";
import NavBar from "../NavBar/NavBar";
import { GrClose, GrMenu } from "react-icons/gr";
import { BsChevronDoubleDown } from "react-icons/bs";

const Header = () => {
  // const iconSize: number = 24;

  // //menu toggle
  // const [isMenuActive, setMenuActive] = useState(false);
  // const menuToggle = () => {
  //   setMenuActive(!isMenuActive);
  // };

  //scroll to main section
  // const scrollToView = () => {
  //   if(window.screen.width >= 769) {
  //     document.getElementById("main-menu")?.scrollIntoView({ behavior: "smooth" });
  //   }
  //   else {
  //     document.getElementById("main")?.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  return (
    <header>
      {/* <section className="header-main-content">
        {displayTopBanner && (
          <div className="content-top">
            <h1 className="title">
              <a href="/">{process.env.REACT_APP_STORE_NAME}</a>
            </h1>
            <p className="description">Thoughts, stories & ideas</p>
          </div>
        )}
        <div className="menu-toggle" onClick={() => menuToggle()}>
          {!isMenuActive ? (
            <GrMenu size={iconSize} />
          ) : (
            <GrClose size={iconSize} />
          )}
        </div>
        <div className={`content-bottom ${!isMenuActive ? "" : "active"}`}>
          <NavBar />
        </div>
      </section>
      {displayTopBanner && (
        <div
          className="btn scroll-down animate__animated animate__shakeY animate__infinite animate__slower"
          onClick={() => scrollToView()}
        >
          <span>
            <BsChevronDoubleDown size={iconSize} />
          </span>
        </div>
      )} */}
    </header>
  );
};

export default Header;
