import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import { useAuth0 } from "@auth0/auth0-react";
import SearchBar from "../SearchBar/SearchBar";
import { BiLogIn, BiMenu } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "../Modal/Modal";

//redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";

//loading toggle
import { modalOpen, modalClose } from "../../redux/slices/ModalSlice";
import {
  ADD_BLOG,
  LOGGED_IN_USER_EMAIL,
  LOGGED_IN_USER_IMAGE_URL,
} from "../../enums/type";
import NavBarPlaceHolder from "./NavBarPlaceHolder";

export const menuItems = [
  {
    name: "Home",
    url: "/"
  },{
    name: "Contact us",
    url: "/contact-us"
  }
]

const NavBar = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const iconSize = 24;
  const [menuStatus, setMenuStatus] = useState(false);
  const [addBlogModalStatus, setAddBlogModalStatus] = useState(false);

  const dispatch = useAppDispatch();

  const menuToggle = () => {
    const body = document.body;
    if (!menuStatus) {
      body.setAttribute("style", "overflow: hidden;");
    } else {
      body.removeAttribute("style");
    }
    setMenuStatus(!menuStatus);
  };

  const addBlogModalToggle = () => {
    const body = document.body;
    if (!addBlogModalStatus) {
      body.setAttribute("style", "overflow: hidden;");
    } else {
      body.removeAttribute("style");
    }
    setAddBlogModalStatus(!addBlogModalStatus);
  };

  const RenderAddBlogModal = () => {
    return (
      <div className="modal-container">
        <div className="modal-content">
          <div className="modal-top">
            <div className="action">
              {/* <div className="new-window">
            <a href={`/blog/${blogId}`} target="_blank">
              <RxOpenInNewWindow size={20} />
              <span>Open in a new tab</span>
            </a>
          </div> */}
            </div>
            <div className="popup-toggle" onClick={() => addBlogModalToggle()}>
              <span>
                <IoCloseSharp size={iconSize} />
              </span>
            </div>
          </div>
          <Modal />
        </div>
      </div>
    );
  };

  if (!isLoading && isAuthenticated) {
    if (user?.email) localStorage.setItem(LOGGED_IN_USER_EMAIL, user?.email);
    if (user?.picture)
      localStorage.setItem(LOGGED_IN_USER_IMAGE_URL, user?.picture);
  }

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-fk1066mvssr3eklh.au.auth0.com";

      try {
        const accessToken = await getAccessTokenSilently();
      } catch (e: any) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  });

  if (isLoading) {
    return <NavBarPlaceHolder />;
  } else {
    return (
      <>
        <div className={`navbar-wrapper sticky`}>
          <nav className="navbar-content">
            <div className="content-left">
              <div className="main-logo">
                <a href="/">
                  {/* <img
                    width={50}
                    height={50}
                    src="/images/omg-setup-logo.png"
                    alt="main-logo"
                  /> */}
                  <svg width="32" height="32" className="UP8CN" viewBox="0 0 32 32" version="1.1" aria-labelledby="omgsetups-home" aria-hidden="false"><desc lang="en-US">omgsetups logo</desc><title id="omgsetups-home">omgsetups home</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg>
                </a>
              </div>
              <div className="search">
                <SearchBar />
              </div>
            </div>
            <div className="content-right">
              <div className={`main-menu ${menuStatus ? "active" : ""}`}>
                <div className="menu-close" onClick={menuToggle}>
                  <span>
                    <IoCloseSharp size={iconSize} />
                  </span>
                </div>
                <ul>
                  {menuItems.map((item, index) => (
                    <li key={index} className="menu-item">
                      <Link to={item.url}>{item.name}</Link>
                    </li>
                  ))}
                  {isAuthenticated ? (
                    <>
                      <li className="menu-item profile">
                        <Link to="/profile">
                          <img
                            src={user?.picture}
                            alt={user?.name}
                            width={32}
                            height={32}
                          />
                        </Link>
                        {/* <div className="dropdown">
                          <div className="item">
                            <Link to="/profile">View my profile</Link>
                          </div>
                          <div className="item">
                            <button
                              onClick={() =>
                                dispatch(
                                  modalOpen({ type: ADD_BLOG, blogId: null })
                                )
                              }
                            >
                              <span>Create a post</span>
                            </button>
                          </div>
                        </div> */}
                      </li>
                    </>
                  ) : (
                    <li className="menu-item login">
                      {/* <Link to="/profile">Login</Link> */}
                      <a onClick={() => loginWithRedirect()}>
                        <span>
                          <BiLogIn />
                        </span>
                        &nbsp;<span>Log In</span>
                      </a>
                    </li>
                  )}
                                  {isAuthenticated && (
                <li className="menu-item add-blog">
                    <a onClick={() => dispatch(modalOpen({ type: ADD_BLOG, blogId: null  }))}>
                      <span>Create a post</span>
                    </a>
                </li>
                )}
                  <li className="menu-item theme-switcher">
                    <ThemeSwitcher />
                  </li>
                </ul>
              </div>
              <div className="menu-open" onClick={menuToggle}>
                <span>
                  <BiMenu size={iconSize} />
                </span>
              </div>
            </div>
          </nav>
        </div>
        {addBlogModalStatus && <RenderAddBlogModal />}
      </>
    );
  }
};

export default NavBar;