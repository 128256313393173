import React from "react";
import { SocialSharingType } from "../../types/BlogType";
import { Helmet } from "react-helmet-async";

const SocialSharing = ({ data }: { data: SocialSharingType }) => {
  const domain = window.location.origin;
  return (
    <>
      <Helmet>
      <script type='text/javascript' src='https://platform-api.sharethis.com/js/sharethis.js#property=640f2ec6e78c0b001306b8dc&product=inline-share-buttons' async></script>
        <script>
          window.__sharethis__.initialize()
        </script>
      </Helmet>
      <div
        className="sharethis-inline-share-buttons"
        data-url={`${domain}${data.url}`}
        data-image={data.image}
        data-title={data.title}
        data-description={data.description}
      ></div>
    </>
  );
};

export default SocialSharing;
