import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { IoCloseSharp } from "react-icons/io5";
import { BsFilter } from "react-icons/bs";
//redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  filterOpen,
  filterClose,
  handleFilterContentChange,
  clearAllFilter,
} from "../../redux/slices/FilterSlice";
import { useNavigate } from "react-router-dom";

const Filter = () => {
  const iconSize = 32;
  const dispatch = useAppDispatch();
  const isFilterActive = useAppSelector((state) => state.filter.isOpen);
  const content = useAppSelector((state) => state.filter.content);
  const displayClearAllButton = useAppSelector(
    (state) => state.filter.displayClearAllButton
  );

  const activeFilter = content.filter(
    (element: any) => element.selected === true
  );

  const navigate = useNavigate();
  return (
    <div className="filter-wrapper">
      <div className={`filter-container ${isFilterActive ? "active" : ""}`}>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {content.map((tag: any, index: number) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() =>
                        dispatch(handleFilterContentChange({ tagId: tag.id }))
                      }
                      checked={tag.selected}
                      name={tag.id}
                    />
                  }
                  label={tag.name}
                  key={index}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        {displayClearAllButton && (
          <button
            className="filter-clear-all"
            onClick={() => {
              dispatch(clearAllFilter());
            }}
          >
            Clear All
          </button>
        )}
      </div>
      <button
        className="filter-button"
        onClick={() => {
          isFilterActive ? dispatch(filterClose()) : dispatch(filterOpen());
        }}
      >
        {!isFilterActive && activeFilter.length > 0 && (
          <span className="active-filter-count">{activeFilter.length}</span>
        )}
        <span className="filter-icon">
          {isFilterActive ? (
            <IoCloseSharp size={iconSize} />
          ) : (
            <BsFilter size={iconSize} />
          )}
        </span>
      </button>
    </div>
  );
};

export default Filter;
