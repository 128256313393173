import React from "react";
import { SlUserFollow } from "react-icons/sl";

const BlogUser = ({
  avatar,
  profile,
  link,
}: {
  avatar: string;
  profile: string;
  link: string;
}) => {
  return (
    <>
      <div className="content-left">
        <a href={link ? link : "#"} target="_blank">
          <div className="avatar">
            <img
              width={40}
              height={40}
              src="/images/avatar_placeholder_large.png"
              alt={profile}
            />
          </div>
          <div className="name">
            <p>{profile}</p>
            <button className="follow">
              <span>
                <SlUserFollow size={20} />&nbsp;Follow
              </span>
            </button>
          </div>
        </a>
      </div>
      <div className="content-right"></div>
    </>
  );
};

export default BlogUser;
