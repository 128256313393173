import React, { useEffect, useLayoutEffect, useState } from "react";
import { ImageType } from "../../types/BlogType";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { AiOutlineDownload, AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import ProgressiveImage from "react-progressive-image-loading";
import  { TbRectangle } from "react-icons/tb";
// // import Swiper core and required modules
// import { Zoom, Navigation, Pagination } from "swiper";

// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/zoom";

const BlogImage = ({ images }: { images: any }) => {
  // const [blogImages, setImages] = useState<any>([]);

  // useEffect(() => {
  //   setImages(images);
  // }, []);

  if (images.length > 0) {
    return (
      <div className="image-container">
        {/* <Swiper
          // install Swiper modules
          modules={[Zoom, Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoHeight={true}
          zoom={true}
          navigation
        > */}
        {images.map((image: any, index: string) => (
          // <SwiperSlide key={index}>
          //   <div className="swiper-zoom-container">
          //     <img
          //       width={500}
          //       height={500}
          //       className="main-image"
          //       src={image.url}
          //       alt={image.title}
          //     />
          //   </div>
          // </SwiperSlide>
          <div className="image-wrapper" key={index} data-src={image.url}>
            {/* <Zoom>
              <ProgressiveImage
                preview="/images/blog_placeholder_large.png"
                src={image.url}
                render={(src, style) => ( */}
                  <img
                    width={300}
                    height={300}
                    src={image.url}
                    alt={image.title}
                    //style={style}
                  />
                {/* )}
              />
            </Zoom> */}

            <div className="image-action">
              <a href={image.url} target="_blank" download>
                <span><TbRectangle /></span>
              </a>
            </div>
          </div>
        ))}
        {/* </Swiper> */}
      </div>
    );
  } else {
    return <p>No images</p>;
  }
};

export default BlogImage;

const CustomZoomContent = ({
  buttonUnzoom, // default unzoom button
  modalState, // current state of the zoom modal: UNLOADED, LOADING, LOADED, UNLOADING
  img, // your image, prepped for zooming
}: //onUnzoom,   // unused here, but a callback to manually unzoom the image and
//   close the modal if you want to use your own buttons or
//   listeners in your custom experience
{
  buttonUnzoom: any; // default unzoom button
  modalState: any; // current state of the zoom modal: UNLOADED, LOADING, LOADED, UNLOADING
  img: any;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useLayoutEffect(() => {
    if (modalState === "LOADED") {
      setIsLoaded(true);
    } else if (modalState === "UNLOADING") {
      setIsLoaded(false);
    }
  }, [modalState]);

  const classCaption = isLoaded
    ? "zoom-caption zoom-caption--loaded"
    : "zoom-caption";

  return (
    <>
      {buttonUnzoom}

      <figure>{img}</figure>
    </>
  );
};
