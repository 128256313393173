import { configureStore } from '@reduxjs/toolkit'

//reducers
import loadingReducer from "../redux/slices/LoadingSlice"; 
import modalReducer from "../redux/slices/ModalSlice";
import filterReducer from "../redux/slices/FilterSlice";
import blogReducer from "../redux/slices/BlogSlice";
import searchReducer from "../redux/slices/SearchSlice";

const rooReducer = {
  loading: loadingReducer,
  modal: modalReducer,
  filter: filterReducer, 
  blog: blogReducer,
  search: searchReducer
}

export const store = configureStore({
  reducer: rooReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch