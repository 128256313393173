import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const graphqlUrl: string =
process.env.REACT_APP_CONTENTFUL_GRAPHQL_BASE_URL !== undefined
  ? process.env.REACT_APP_CONTENTFUL_GRAPHQL_BASE_URL
  : "";

const space: string =
process.env.REACT_APP_CONTENTFUL_SPACE_ID !== undefined
  ? process.env.REACT_APP_CONTENTFUL_SPACE_ID
  : "";

const environment: string =
process.env.REACT_APP_CONTENTFUL_ENVIROMENT_ID !== undefined
  ? process.env.REACT_APP_CONTENTFUL_ENVIROMENT_ID
  : "";

const accessToken: string =
  process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN !== undefined
    ? process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN
    : "";

const httpLink = createHttpLink({
    uri: `${graphqlUrl}/content/v1/spaces/${space}/environments/${environment}`,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}` || "",
    }
  }
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});