import React, { useEffect, useRef, useState } from "react";
import {
  AiFillHeart,
  AiOutlineCheckCircle,
  AiOutlineHeart,
  AiOutlineLike,
} from "react-icons/ai";
import {
  BlogDetailType,
  SocialSharingType,
  UserPostAction,
} from "../../types/BlogType";
import { blogDetailMappping } from "../../utils/TypeMapping";
import BlogDescription from "../../components/BlogDescription/BlogDescription";
import BlogUser from "../../components/BlogUser/BlogUser";
import BlogImage from "../../components/BlogImage/BlogImage";
import SocialSharing from "../../components/SocialSharing/SocialSharing";
import BlogComment from "../../components/BlogComment/BlogComment";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  getBlogDetail,
  getBlogDetailBasedOnTime,
  getBlogsInCollection,
  getUserCollectedBlogs,
  getUserCollectionList,
  getUserLikedBlogs,
} from "../../apollo/queries";
import { apolloClient } from "../../apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineCancel,
} from "react-icons/md";
import {
  BsBookmark,
  BsBookmarks,
  BsBookmarksFill,
  BsFillBookmarkFill,
} from "react-icons/bs";

//redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";

//loading toggle
import { loadingOpen, loadingClose } from "../../redux/slices/LoadingSlice";
import { timeout } from "../../utils/Delay";
import {
  BLOG_DETAIL,
  LOGGED_IN_USER_EMAIL,
  USER_COLLECTION,
} from "../../enums/type";
import { modalChangeContent, modalOpen } from "../../redux/slices/ModalSlice";
import { toggleLikeBlog, toggleCollectedBlog } from "../../middleware/BlogApi";
import { useAuth0 } from "@auth0/auth0-react";
import { isTypeSystemExtensionNode } from "graphql";

const BlogDetail = ({ id }: { id: string }) => {
  const { loginWithRedirect } = useAuth0();

  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector((state) => state.modal.isOpen);
  const [isLiked, setLikeStatus] = useState(false);
  const [isInCollection, setCollection] = useState(false);
  const userEmail = localStorage.getItem(LOGGED_IN_USER_EMAIL);
  const [blogSysId, setBlogSysId] = useState("");
  const [isCreateCollectionFormActive, setCreateCollectionFormStatus] =
    useState(false);
  const [isCollectionDropdownActive, setCollectionDropdownStatus] =
    useState(false);
  const [blogContent, setBlogContent] = useState<BlogDetailType | undefined>(
    undefined
  );
  const [userCollections, setUserCollections] = useState<string[]>([]);
  const [collectionNames, setCollectionNames] = useState<any[]>([]);

  let { blogId } = useParams();
  const Head = () => {
    if (blogId) {
      return (
        <Helmet>
          <title>
            {`${process.env.REACT_APP_STORE_NAME} - ${blogContent?.title}`}
          </title>
        </Helmet>
      );
    } else {
      return <></>;
    }
  };

  if (!blogId) blogId = id;

  const getBlogLikeStatus = () => {
    //fetch blog like button status
    if (userEmail) {
      apolloClient
        .query({
          query: gql`
            ${getUserLikedBlogs(userEmail, blogId || "")}
          `,
        })
        .then((result) => {
          const likedBlogsCollection: any =
            result.data.likedBlogsCollection.items;
          if (likedBlogsCollection.length > 0) {
            setLikeStatus(true);
          }
        });
    }
  };

  const handleLikeButtonChange = (event: any) => {
    event.stopPropagation();
    if (userEmail && blogId) {
      const userPostActionData: UserPostAction = {
        userEmail,
        blogId: blogId,
      };
      toggleLikeBlog(isLiked, userPostActionData);
      setLikeStatus(!isLiked);
    } else {
      loginWithRedirect();
    }
  };

  // const handleCollectionButtonChange = (event: any) => {
  //   event.stopPropagation();
  //   if (userEmail && blogId) {
  //     const userPostActionData: UserPostAction = {
  //       userEmail,
  //       blogId: blogId,
  //     };
  //     toggleLikeBlog(isLiked, userPostActionData);
  //     setLikeStatus(!isLiked);
  //   } else {
  //     loginWithRedirect();
  //   }
  // };

  const getBlogCollectionStatus = () => {
    if (userEmail) {
      apolloClient
        .query({
          query: gql`
            ${getUserCollectedBlogs(userEmail, blogId || "")}
          `,
        })
        .then((result) => {
          const collectedBlogs: any =
            result.data.blogCollectionCollection.items;
          if (collectedBlogs.length > 0) {
            setCollection(true);
          }
        });
    }
  };

  const toggleCollectionDropDown = async () => {
    if (isCollectionDropdownActive === false && userEmail) {
      const collectionNames = await apolloClient
        .query({
          query: gql`
            ${getUserCollectionList(userEmail)}
          `,
        })
        .then((result) => {
          const userCollectionList = result.data.userCollectionListCollection.items;
          return userCollectionList.map((item : any) => {
            return item.collectionName;
          });
        });



        const collectionsAndBlogs = await apolloClient
        .query({
          query: gql`
            ${getBlogsInCollection(
              userEmail,
              blogId || ""
            )}
          `,
        })
        .then((result) => {
          const blogCollectionCollection =
            result.data.blogCollectionCollection.items;
          return blogCollectionCollection.map((item : any) => {
            return item.collectionName;
          });
        }); 


        let blogCollectionStatus = [];

        blogCollectionStatus = collectionNames.map((item : any) => {
          const isAdded = collectionsAndBlogs.includes(item);
          return { name: item, isAdded: isAdded}
        })

        setCollectionNames(blogCollectionStatus);

    }
    setCollectionDropdownStatus(!isCollectionDropdownActive);
  };

  const handleCollectionAddButtonChange = (
    event: any,
    collection : any
  ) => {
    event.stopPropagation();
    const {name, isAdded} = collection;

    
    //if is in collection, then remove from collection
    if(isAdded) {

    }
    else {
      
    }
  };

  const openCollectionModal = (event: any) => {
    dispatch(modalOpen({ type: USER_COLLECTION, blogId: blogId }));
  };

  const createCollection = (event: any) => {
    setCreateCollectionFormStatus(true);
  };

  const cancelCreateCollection = (event: any) => {
    setCreateCollectionFormStatus(false);
  };

  const handleBlogChange = async (operator: string) => {
    dispatch(loadingOpen());
    apolloClient
      .query({
        query: gql`
          ${getBlogDetailBasedOnTime(
            blogContent?.sys.firstPublishedAt,
            operator
          )}
        `,
      })
      .then((result) => {
        const blogDetailId = result.data.itemPostCollection.items[0].sys.id;
        if (blogDetailId) {
          apolloClient
            .query({
              query: gql`
                ${getBlogDetail(blogDetailId || "")}
              `,
            })
            .then((result) => {
              const contentfulBlogDetail: any = result.data.itemPost;
              let blogDetail: BlogDetailType =
                blogDetailMappping(contentfulBlogDetail);
              setBlogContent(blogDetail);
              window.history.replaceState(null, "", `/blog/${blogDetailId}`);
            });
          if (!isModalOpen) {
            dispatch(
              modalChangeContent({ type: BLOG_DETAIL, blogId: blogDetailId })
            );
          } else {
            dispatch(modalOpen({ type: BLOG_DETAIL, blogId: blogDetailId }));
          }
        }
      });

    await timeout(1000);
    dispatch(loadingClose());
  };

  const handleNewCollectionInputChange = (event: any) => {};

  const newCollectionInputRef = useRef<any>();

  const submitNewCollection = (event: any) => {
    const newCollectionName = newCollectionInputRef.current.value;
    if (newCollectionName) {
      const newCollections = [...userCollections, newCollectionName];
      setUserCollections(newCollections);
      newCollectionInputRef.current.value = "";
    }
  };

  useEffect(() => {
    dispatch(loadingOpen());
    apolloClient
      .query({
        query: gql`
          ${getBlogDetail(blogId || "")}
        `,
      })
      .then((result) => {
        const contentfulBlogDetail: any = result.data.itemPost;
        let blogDetail: BlogDetailType =
          blogDetailMappping(contentfulBlogDetail);
        setBlogContent(blogDetail);
        window.history.replaceState(null, "", `/blog/${blogId}`);
      });
    getBlogLikeStatus();
    getBlogCollectionStatus();
    dispatch(loadingClose());
  }, []);

  //handle swipe to navigate blog
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 100;

  const onTouchStart = (event: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(event.targetTouches[0].clientX);
  };

  const onTouchMove = (event: any) =>
    setTouchEnd(event.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleBlogChange("_lt");
    }
    if (isRightSwipe) {
      handleBlogChange("_gt");
    }
  };

  if (blogContent) {
    const socialSharingData: SocialSharingType = {
      url: `${process.env.PUBLIC_URL}/blog/${blogId}`,
      title: blogContent.title || "Unkown Title",
      description: blogContent.description || "",
      image: blogContent.mediaFilesCollection.items[0].url,
    };
    return (
      <>
        <Head />
        <div
          className="blog-detail-wrapper wrapper"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <div className="blog-detail">
            <div className="pagination">
              <button onClick={() => handleBlogChange("_gt")}>
                <span>
                  <MdKeyboardArrowLeft />
                  &nbsp;Previous Post
                </span>
              </button>
              <button onClick={() => handleBlogChange("_lt")}>
                <span>
                  Next Post&nbsp;
                  <MdKeyboardArrowRight />
                </span>
              </button>
            </div>
            <div className="blog-main-content">
              <div className="content-left">
                {blogContent.mediaFilesCollection.items && (
                  <BlogImage images={blogContent.mediaFilesCollection.items} />
                )}
              </div>
              <div className="content-right">
                <div className="content-top">
                  <div className="user-info">
                    <BlogUser
                      avatar={blogContent.userProfileLink}
                      profile={blogContent.userProfile}
                      link={blogContent.userProfileLink}
                    />
                  </div>
                  <div className="action-group">
                    <div>
                      <button
                        className={`blog-like ${isLiked ? "liked" : ""}`}
                        onClick={(event: any) => handleLikeButtonChange(event)}
                      >
                        <span>
                          {isLiked ? (
                            <>
                              <AiFillHeart />
                              &nbsp;Liked
                            </>
                          ) : (
                            <>
                              <AiOutlineHeart />
                              &nbsp;Like
                            </>
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="collection-dropdown">
                      <button
                        className="blog-collection-add"
                        onClick={() => toggleCollectionDropDown()}
                      >
                        <span>
                          {isInCollection ? (
                            <BsBookmarksFill />
                          ) : (
                            <BsBookmarks />
                          )}
                          &nbsp;
                          {isInCollection
                            ? `Added to collection`
                            : `Add to collection`}
                        </span>
                      </button>
                      <div
                        className={`collection-group ${
                          isCollectionDropdownActive ? "active" : ""
                        }`}
                      >
                        <ul>
                          {collectionNames.map((item: any) => (
                            <li
                              className={`item ${item.isAdded ? "added" : ""}`}
                              onClick={(event: any) =>
                                handleCollectionAddButtonChange(event, item)
                              }
                            >
                              <p>{item.name}</p>
                              {item.isAdded ? <span><AiOutlineCheckCircle size={22} /></span> : <></>}
                            </li>
                          ))}
                          <li className="item">
                            {isCreateCollectionFormActive ? (
                              <>
                                <input
                                  ref={newCollectionInputRef}
                                  className="new-collection-input"
                                  placeholder="Your collection name"
                                  onChange={(event: any) =>
                                    handleNewCollectionInputChange(event)
                                  }
                                ></input>
                                <button
                                  className="add-collection-btn"
                                  onClick={(event: any) =>
                                    submitNewCollection(event)
                                  }
                                >
                                  <span>
                                    <AiOutlineCheckCircle />
                                  </span>
                                </button>
                                <button
                                  onClick={(event: any) =>
                                    cancelCreateCollection(event)
                                  }
                                  className="cancel-collection-btn"
                                >
                                  <span>
                                    <MdOutlineCancel />
                                  </span>
                                </button>
                              </>
                            ) : (
                              <button
                                className="create-collection-btn"
                                onClick={(event: any) =>
                                  createCollection(event)
                                }
                              >
                                Create collection
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <SocialSharing data={socialSharingData} />
                  <div className="blog-info">
                    <h1 className="title">{blogContent.title}</h1>
                  </div>
                  {blogContent.description && (
                    <BlogDescription
                      blogDescription={blogContent.description}
                    />
                  )}
                  <span className="blog-date">
                    Published{" "}
                    {moment(blogContent.sys.firstPublishedAt).fromNow()}
                  </span>
                  <div className="blog-interaction">
                    <BlogComment
                      blogId={blogId}
                      blogTitle={blogContent.title}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Head />
        <div className="ph-item blog-item-wrapper">
          <div className="ph-col-12">
            <div className="ph-picture"></div>
            <div className="ph-row">
              <div className="ph-col-6 big"></div>
            </div>
            <div className="ph-row">
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 big"></div>
            </div>
            <div className="ph-row">
              <div className="ph-col-6 big"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default BlogDetail;