export const getAllBlogIds = () => `
query{
	itemPostCollection(preview:false,order:sys_firstPublishedAt_DESC) {
    items {
      itemId,
      sys {
        id,
        firstPublishedAt
      },
      contentfulMetadata{
        tags {
          id,
          name
        }
      }
    }
  }
}
`;

export const getAllBlogIdsBySearchQuery = (query: string) => `
query{
	itemPostCollection(preview:false,order:sys_firstPublishedAt_DESC, where:{title_contains:"${query}"}) {
    items {
      itemId,
      sys {
        id,
        firstPublishedAt
      },
      contentfulMetadata{
        tags {
          id,
          name
        }
      }
    }
  }
}
`;

export const getBlogDetail = (blogId: string) => `
query{
    itemPost(preview:false,id:"${blogId}") {
    itemId,
    title,
    postedBy,
    description,
    mediaLinks,
    mediaFilesCollection {
        items {
        url,
        title
        }
    },
    sourceLink,
    contentfulMetadata {
        tags {
        id,
        name
        }
    },
    userProfile,
    userProfileLink,
    sys {
      firstPublishedAt
    }
    }
}
`;

//operator _gt, _lt
//timeStamp 2023-04-01T06:31:50.076Z
export const getBlogDetailBasedOnTime = (
  timeStamp: string,
  operator: string
) => {
  const sortBy = operator === "_gt" ? "ASC" : "DESC";
  return `
query{
	itemPostCollection(limit:1,preview:false,order:sys_firstPublishedAt_${sortBy}, where:{sys:{firstPublishedAt${operator}:"${timeStamp}" }}) {
    items {
      itemId,
      sys {
        id,
        firstPublishedAt
      },
      contentfulMetadata{
        tags {
          id,
          name
        }
      }
    }
  }
}`;
};

export const getBlogIdsByTags = (tagIds: string[]) => {
  let condition = "";

  tagIds.map((tagId: string) => {
    condition =
      condition + `{contentfulMetadata:{tags: {id_contains_all:"${tagId}"}}},`;
  });

  return `
query{
	itemPostCollection(preview:false,order:sys_firstPublishedAt_DESC, where:
    {OR: [
      ${condition}
    ]}
    ) {
    items {
      itemId,
      sys {
        id,
        firstPublishedAt
      }
    }
  }
}
`;
};

export const getUserLikedBlogs = (userEmail: string, blogId: string) => {
  return `
  query{
    likedBlogsCollection(preview:false, where: { AND: [{userEmail:"${userEmail}"},{blogId:"${blogId}"}]}) {
      items {
        blogId,
        sys {
          id,
          firstPublishedAt
        }
      }
    }
  }
`;
};

export const getAllUserLikedBlogIds = (userEmail: string) => {
  return `
  query{
    likedBlogsCollection(preview:false, where: {userEmail:"${userEmail}"}) {
      items {
        blogId,
        sys {
          id,
          firstPublishedAt
        }
      }
    }
  }
`;
}

export const getBlogsByIds = (blogIds : string[]) => {
  const blogIdsAsString = blogIds.join(`","`);

return `
  query{
    itemPostCollection(preview:false,order:sys_firstPublishedAt_DESC, where:{ sys:{id_in:["${blogIdsAsString}"]}}) {
      items {
        itemId,
        sys {
          id,
          firstPublishedAt
        },
        contentfulMetadata{
          tags {
            id,
            name
          }
        }
      }
    }
  }
`
}

export const getUserCollectedBlogs = (userEmail: string, blogId: string) => {
  return `
  query{
    blogCollectionCollection(preview:false, where: { AND: [{userEmail:"${userEmail}"},{blogId:"${blogId}"}]}) {
      items {
        blogId,
        sys {
          id,
          firstPublishedAt
        }
      }
    }
  }
`;
};

export const getUserCollectionList = (userEmail: string) => {
  return `
    query{
      userCollectionListCollection(preview:false, where: { AND: [{userEmail:"${userEmail}"}]}) {
        items {
          collectionName,
          sys {
            id,
            firstPublishedAt
          }
        }
      }
    }
`;
}

export const getBlogsInCollection = (userEmail: string, blogId : string) => {
  return `
    query{
      blogCollectionCollection(preview:false, where: { AND: [{userEmail:"${userEmail}"},{blogId:"${blogId}"}]}) {
        items {
          userEmail,
          blogId,
          collectionName,
          sys {
            id,
            firstPublishedAt
          }
        }
      }
    }
`;
}