import { createSlice } from "@reduxjs/toolkit";

interface SearchState {
    searchQuery: string;
}

const initialState : SearchState = {
    searchQuery: ""
}

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        handleSearch: (state, action) => {
            state.searchQuery = action.payload.searchQuery;
        }
    }
})

export const { handleSearch } = searchSlice.actions;

export default searchSlice.reducer;