import React from 'react'
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Oops!!! Page not found</h2>
      <Link to="/" className="back btn">Back to Home</Link>
    </div>
  )
}

export default NotFound