import React from 'react'
import SearchBar from '../SearchBar/SearchBar'
import { IoCloseSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';
import { BiMenu } from 'react-icons/bi';

const NavBarPlaceHolder = () => {
    const iconSize = 24;
    
  return (
    <>
      <div className={`navbar-wrapper sticky`}>
        <nav className="navbar-content">
          <div className="content-left">
            <div className="main-logo">
              <a href="/">
                <img
                  width={50}
                  height={50}
                  src="/images/omg-setup-logo.png"
                  alt="main-logo"
                />
              </a>
            </div>
            <div className="search">
              <SearchBar />
            </div>
          </div>
          <div className="content-right">
            <div className={`main-menu`}>
              <div className="menu-close">
                <span>
                  <IoCloseSharp size={iconSize} />
                </span>
              </div>
              <ul>
                  <li className="menu-item">
                    <Link to="#">Home</Link>
                  </li>
                <li className="menu-item theme-switcher">
                  <ThemeSwitcher />
                </li>
              </ul>
            </div>
            <div className="menu-open">
              <span>
                <BiMenu size={iconSize} />
              </span>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default NavBarPlaceHolder