import React from "react";

const CardPlaceHolder = () => {
  return (
    <div className="ph-item blog-item-wrapper">
      <div className="ph-col-12">
        <div className="ph-picture"></div>
      </div>
    </div>
  );
};

export default CardPlaceHolder;
