import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useAppDispatch } from "../../app/hooks";
import { loadingClose, loadingOpen } from "../../redux/slices/LoadingSlice";
import { LOGGED_IN_USER_EMAIL, LOGGED_IN_USER_IMAGE_URL } from "../../enums/type";
import BlogGrid from "../../components/BlogGrid/BlogGrid";

const Profile = () => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0();

  const dispatch = useAppDispatch();

  if (isLoading) {
    dispatch(loadingOpen());
  }
  else {
    dispatch(loadingClose());
  }
  return (
    <>
      <div className="page-wrapper">
        {isAuthenticated && (
          <>
            <div className="profile-wrapper">
              <div className="content-left">
                <img
                  src={user?.picture}
                  alt={user?.name}
                  width={150}
                  height={150}
                />
              </div>
              <div className="content-right">
                <h2 className="user-name">{user?.name}</h2>
                <p className="user-email">{user?.email}</p>
                <button
                  className="user-logout"
                  onClick={() => {
                    localStorage.removeItem(LOGGED_IN_USER_EMAIL);
                    localStorage.removeItem(LOGGED_IN_USER_IMAGE_URL);
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    });
                  }}
                >
                  Log out
                </button>
              </div>
            </div>
            <div className="content">
              <BlogGrid userEmail={user?.email || ""} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Profile;