import React, { useState } from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const BlogDescription = ({
  blogDescription,
}: {
  blogDescription: string;
}) => {
  const [showFullDescription, setFullDescription] = useState(false);
  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  var descClass : string = `desc `;
  if(showFullDescription) descClass = `${descClass} expanded`; 

  return (
    <>
      <div
        className={descClass}
      >
        <ReactMarkdown linkTarget={"_blank"} children={blogDescription} remarkPlugins={[remarkGfm]} />
      </div>
    </>
  );
};

export default BlogDescription;
