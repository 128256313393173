import React, { useEffect, useRef } from "react";
import { IoCloseSharp } from "react-icons/io5";

//redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";

//loading toggle
import { modalOpen, modalClose } from "../../redux/slices/ModalSlice";
import BlogDetail from "../../pages/BlogDetail/BlogDetail";
import AddBlog from "../../pages/BlogDetail/AddBlog";
import { ADD_BLOG, BLOG_DETAIL, USER_COLLECTION } from "../../enums/type";
import UserCollection from "../UserCollection/UserCollection";

const Modal: any = () => {
  const { type, blogId } = useAppSelector((state) => state.modal.payload);
  const dispatch = useAppDispatch();
  const iconSize = 20;

  //close modal if clicked outside
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        dispatch(modalClose(null));
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const RenderChildComponent = () => {
    switch (type) {
      case BLOG_DETAIL:
        return <BlogDetail id={blogId} />;
      case ADD_BLOG:
        return <AddBlog />;
      case USER_COLLECTION:
        return <UserCollection />
      default:
        return <p>No Content</p>;
    }
  };

  return (
    <div className="modal-container">
      <div className="modal-content" ref={modalRef}>
        <div className="modal-top">
          <div className="action"></div>
          <div
            className="popup-toggle"
            onClick={() => dispatch(modalClose(null))}
          >
            <span>
              <IoCloseSharp size={iconSize} />
            </span>
          </div>
        </div>
        <RenderChildComponent />
      </div>
    </div>
  );
};

export default Modal;