import React, { useEffect, useState } from "react";
import { CommentType } from "../../types/BlogType";
import moment from "moment";
import { DiscussionEmbed } from "disqus-react";
import { useAuth0 } from "@auth0/auth0-react";

const BlogComment = ({
  blogId,
  blogTitle,
}: {
  blogId: string;
  blogTitle: string;
}) => {
  const {
    isAuthenticated,
    user,
  } = useAuth0();

  const shortName: string =
    process.env.REACT_APP_DISQUS_SHORT_NAME !== undefined
      ? process.env.REACT_APP_DISQUS_SHORT_NAME
      : "";

  return (
    <>
    <DiscussionEmbed
      shortname={`${shortName}`}
      config={{
        identifier: `/blog/${blogId}`,
        title: `${blogTitle}`,
        language: "en_US",
        // apiKey: "6jirUVpS0SjkaY07vsiBPEXE25ZG6wh00moKSLHmGy4sZORriFsnn6PN7wzKWURN"
      }}
    />
    </>
  );
};
export default BlogComment;
