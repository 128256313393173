import { createSlice } from "@reduxjs/toolkit";
import { apolloClient } from "../../apollo/client";
import {
  getAllBlogIds,
  getAllBlogIdsBySearchQuery,
  getBlogIdsByTags,
} from "../../apollo/queries";
import { gql } from "@apollo/client";

interface BlogTag {
  id: string;
  name: string;
}

interface Blog {
  itemId: string;
  contentfulId: string;
  tag: BlogTag[];
}

interface BlogState {
  blogList: Blog[]
}
const initialState: BlogState = {
  blogList: []
};

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    displayAllBlogs: (state, action) => {
      state.blogList = action.payload;
    },
    // displayFilteredBlogs: (state, action) => {
    //   state.blogIdList = action.payload.blogIdList;
    // },
    // displaySearchedBlogs: (state) => {},
  },
});

export const { displayAllBlogs } =
  blogSlice.actions;

export default blogSlice.reducer;
