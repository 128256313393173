import React, { useEffect, useState } from "react";
import { BlogDetailType, UserPostAction } from "../../types/BlogType";
import { blogDetailMappping } from "../../utils/TypeMapping";
import { gql } from "@apollo/client";
import { apolloClient } from "../../apollo/client";
import { getBlogDetail, getUserLikedBlogs } from "../../apollo/queries";
import CardPlaceHolder from "./CardPlaceHolder";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useAuth0 } from "@auth0/auth0-react";

//redux
import { useAppDispatch } from "../../app/hooks";

//loading toggle
import { modalOpen } from "../../redux/slices/ModalSlice";
import { BLOG_DETAIL, LOGGED_IN_USER_EMAIL } from "../../enums/type";
import { toggleLikeBlog } from "../../middleware/BlogApi";

const Card = ({ blogId }: { blogId: string }) => {
  const {
    user,
    loginWithRedirect,
    isAuthenticated,
    isLoading
  } = useAuth0();

  const dispatch = useAppDispatch();
  const userEmail = localStorage.getItem(LOGGED_IN_USER_EMAIL);
  const [blogContent, setBlogContent] = useState<BlogDetailType | undefined>(
    undefined
  );
  const [isLiked, setLikeStatus] = useState(false);
  const [isInCollection, setCollection] = useState(false);
  const [blogSysId, setBlogSysId] = useState("");

  const getBlogInfo = () => {
    //fetch blog detail
    apolloClient
      .query({
        query: gql`
          ${getBlogDetail(blogId)}
        `,
      })
      .then((result) => {
        const contentfulBlogDetail: any = result.data.itemPost;
        let blogDetail: BlogDetailType =
          blogDetailMappping(contentfulBlogDetail);
        setBlogContent(blogDetail);
      });
  };

  const getBlogLikeStatus = () => {
    //fetch blog like button status
    if (userEmail) {
      apolloClient
        .query({
          query: gql`
            ${getUserLikedBlogs(userEmail, blogId || "")}
          `,
        })
        .then((result) => {
          const likedBlogsCollection: any =
            result.data.likedBlogsCollection.items;
          if (likedBlogsCollection.length > 0) {
            setBlogSysId(likedBlogsCollection[0].sys.id);
            setLikeStatus(true);
          }
        });
    }
  };

  const handleLikeButtonChange = async (event: any) => {
    event.stopPropagation();
    if (isAuthenticated) {
      const userPostActionData: UserPostAction = {
        userEmail: user?.email || "",
        blogId: blogId,
      };
      toggleLikeBlog(isLiked, userPostActionData);
      setLikeStatus(!isLiked);
    } else {
      loginWithRedirect();
    }
  };

  useEffect(() => {
    getBlogInfo();
    getBlogLikeStatus();
  }, [blogId]);

  if (blogContent) {
    try {
      return (
        <>
          <div
            className="blog-item-wrapper"
            id={`blog-${blogId}`}
            onClick={() =>
              dispatch(modalOpen({ type: BLOG_DETAIL, blogId: blogId }))
            }
          >
            <a
              className="hidden blog-detail"
              href={`${window.location.origin}/blog/${blogId}`}
            >
              {blogContent.title}
            </a>
            <div className="blog-img">
              <img
                width={300}
                height={300}
                src={blogContent.mediaFilesCollection.items[0].url}
                alt={blogContent.mediaFilesCollection.items[0].title}
              />
              {/* <ProgressiveImage
                preview="/images/blog_placeholder_large.png"
                src={blogContent.mediaFilesCollection.items[0].url}
                render={(src, style) => (
                  <img
                    width={300}
                    height={300}
                    src={src}
                    alt={blogContent.mediaFilesCollection.items[0].title}
                    style={style}
                  />
                )}
              /> */}
            </div>
            <div className="blog-overlay">
              {/* <p className="blog-title">{blogContent.title}</p> */}

              <div className="blog-user">
                {blogContent.userProfile && (
                  <a
                    target="_blank"
                    href={
                      blogContent.userProfile
                        ? `/author/${blogContent.userProfile}`
                        : "#"
                    }
                  >
                    {blogContent.userProfile}
                  </a>
                )}
              </div>
              <div className="blog-action">
                <button
                  className={`blog-like ${isLiked ? "liked" : ""}`}
                  onClick={(event: any) => handleLikeButtonChange(event)}
                >
                  {isLiked ? <AiFillHeart /> : <AiOutlineHeart />}
                </button>
              </div>
              {blogContent.contentfulMetadata.tags !== undefined &&
              blogContent.contentfulMetadata.tags.length > 0 ? (
                <div className="blog-meta">
                  <div className="tag-group">
                    {blogContent.contentfulMetadata.tags.map(
                      (tag: any, index: number) => (
                        <span className="blog-tag" key={index}>
                          <a href="/tags"># {tag.name}</a>
                        </span>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      );
    } catch (error: any) {
      console.log(error.message);
      return <CardPlaceHolder />;
    }
  } else {
    return <CardPlaceHolder />;
  }
};

export default Card;