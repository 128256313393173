import { createSlice, current } from "@reduxjs/toolkit";

interface Tag {
  id: string;
  name: string;
  selected: boolean;
}

interface FilterState {
  isOpen: boolean;
  content: Tag[];
  displayClearAllButton: boolean;
}

const initialState: FilterState = {
  isOpen: false,
  content: [],
  displayClearAllButton: false,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    filterOpen: (state) => {
      state.isOpen = true;
    },
    filterClose: (state) => {
      state.isOpen = false;
    },
    populateContent: (state, action) => {
      state.content = action.payload;
    },
    handleFilterContentChange: (state, action) => {
      const index = state.content.findIndex(
        (tag: any) => tag.id === action.payload.tagId
      );

      //if the changed item matches, change the selected value
      if (index >= 0) {
        state.content[index].selected = !state.content[index].selected;
      }
      //if at least one filter item is selected, display the clear all button
      const activeFilterItemCount = state.content.filter(
        (tag: any) => tag.selected === true
      ).length;
      if (activeFilterItemCount > 0) {
        state.displayClearAllButton = true;
      } else {
        state.displayClearAllButton = false;
      }
    },
    clearAllFilter: (state) => {
      const currentContent = state.content;
      currentContent.forEach((tag : any) => (tag.selected = false));
      state.content = currentContent;
      state.displayClearAllButton = false;
    },
  },
});

export const {
  filterOpen,
  filterClose,
  populateContent,
  handleFilterContentChange,
  clearAllFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
