import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

//redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { handleSearch } from "../../redux/slices/SearchSlice";

const SearchBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const iconSize = 16;
  const submitSearch = (event: any) => {
    event.preventDefault();
    if (searchQuery) {
      dispatch(handleSearch({ searchQuery: searchQuery }));
      navigate("/search");
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  return (
    <form className="search-form" onSubmit={submitSearch}>
      <button type="submit" className="search-button">
        <span>
          <BsSearch size={iconSize} />
        </span>
      </button>
      <input
        className="search-bar"
        type="text"
        placeholder="Search Posts"
        defaultValue={""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </form>
  );
};

export default SearchBar;
