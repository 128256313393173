import React from 'react'
import MasonryLayout from '../../components/MasonryLayout/MasonryLayout'

const Home = () => {
  return (
    <div className="home-page">
      <MasonryLayout showFilter={true} pageType="" />
    </div>
  )
}

export default Home