import React, { useState } from 'react'
import MasonryLayout from '../MasonryLayout/MasonryLayout';
import UserLikedBlogs from '../MasonryLayout/UserLikedBlogs';
import { AiFillHeart } from 'react-icons/ai';
import { MdCollectionsBookmark } from 'react-icons/md';

const items = [
    {
        icon: <AiFillHeart />,
        title: "Liked Blogs"
    },
    {
        icon: <MdCollectionsBookmark />,
        title: "My Collections"
    }
]

const BlogGrid = ({userEmail} : {userEmail : string}) => {

    const[sectionView, setSectionView] = useState("Liked Blogs");

  return (
    <div>
        
        <ul className="user-blog-groups">
            {
                items.map((item : any) => {
                    return <li className={`item ${(sectionView === item.title) ? "active" : ""}`}><a href="#" onClick={() => setSectionView(item.title)}><span>{item.icon}</span>{item.title}</a></li>
                })
            }
        </ul>
        {
            (sectionView === "Liked Blogs") && <UserLikedBlogs userEmail={userEmail} />    }
            { (sectionView === "My Collections") && <></> }

            </div>
  )
}

export default BlogGrid